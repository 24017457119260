
:root {
  color-scheme: dark;
  font-family: "roboto";
  background: #252525;
}

h1 {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
}

p {
  text-align: right;
  margin-top: 10px;
}

* {
  margin: 0;
}
